<template>
  <div class="mb-8 border-b">
    <div class="relative pb-4">
      <h2 class="pr-12">{{ serviceDetail.serviceTitle }}</h2>
      <div v-html="serviceDetail.serviceIntro" class="pr-12"></div>
      <img class="absolute top-0 right-0 w-8 h-8 cursor-pointer" :src="'/assets/images/page-expand-'+(showing?'minus':'plus')+'.svg'" @click="showing=!showing">
    </div>
    <transition name="slide">
      <div v-if="showing" v-html="serviceDetail.serviceDetail" class="service-details"></div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showing: false,
    }
  },
  props: {
    serviceDetail: {
      required: true,
      type: Object
    },
    last: {
      required: true,
      type: Boolean,
    }
  }
}
</script>

<style scoped>
.slide-enter-active {
   transition-duration: 0.3s;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   transition-duration: 0.3s;
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 300px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>