<template>
  <article class="case-study-card w-full text-black flex shadow rounded-lg overflow-hidden bg-white">
    <a :href="caseStudy.url" class="flex flex-col ">
      <img class="w-full shrink-0 aspect-[3/2]" :src="caseStudy.img" width="900" height="600" />

      <div class="py-4 px-6 grow flex flex-col justify-between">
        <div>

          <h3 class="text-5xl">{{ caseStudy.title }}</h3>
          <div v-if="caseStudy.whatWeDidIds.length">
            <h4 class="uppercase text-xs font-bold">What we did</h4>
            <ul class="mb-8 list-disc list-inside">
              <li class="text-base" v-for="categoryId in caseStudy.whatWeDidIds" :key="categoryId">{{ whatWeDidCategories.find((category) => categoryId === category.id).title }}</li>
            </ul>
          </div>
        </div>
        <div>
          <p class="text-right !text-xs uppercase font-bold mb-1">Read full case study →</p>
          <hr class="border-1 border-black" />
        </div>
      </div>
    </a>
  </article>
</template>

<script>
export default {
  props: {
    caseStudy: {
      required: true,
      type: Object,
    },
    serviceCategories: {
      required: true,
      type: Array,
    },
    whatWeDidCategories: {
      required: true,
      type: Array,
    },
  },
};
</script>
