<template>
  <div class="case-studies-filter" v-if="serviceCategories.length || whatWeDidCategories.length">

  <div class="container container-w-12">
    <div class="p-4 bg-gray-200 sm:flex">

      <div class="mb-4 sm:mb-0">
        <label for="serviceSelect" class="uppercase text-sm font-faktum-extra-bold mr-2">Service:</label>

        <select id="serviceSelect" v-model="currentServiceCategory" @change="updateFilters" class="!bg-white inline-block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-brand-orange mr-8">
          <option value="all">All</option>
          <option v-for="category in serviceCategories" :key="category.id" :value="category.id">
            {{ category.title }}
          </option>
        </select>
      </div>

      <div>
        <label for="whatWeDidSelect" class="uppercase text-sm font-faktum-extra-bold mr-2">What We Did:</label>

        <select id="whatWeDidSelect" v-model="currentWhatWeDidCategory" @change="updateFilters" class="!bg-white inline-block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-brand-orange">
          <option value="all">All</option>
          <option v-for="category in whatWeDidCategories" :key="category.id" :value="category.id"> {{ category.title }}</option>
        </select>
      </div>

    </div>
  </div>

</div>


</template>

<script>

export default {
  props: {
    serviceCategories: {
      required: true,
      type: Array,
    },
    whatWeDidCategories: {
      required: true,
      type: Array,
    },
    selectedServiceCategory: {
    },
    selectedWhatWeDidCategory: {
    },
  },
  data() {
    return {
      currentServiceCategory: this.selectedServiceCategory,
      currentWhatWeDidCategory: this.selectedWhatWeDidCategory,
    };
  },
  watch:{
    selectedServiceCategory() {
      this.currentServiceCategory = this.selectedServiceCategory;
    },
    selectedWhatWeDidCategory() {
      this.currentWhatWeDidCategory = this.selectedWhatWeDidCategory;
    },
  },
  methods: {
    updateFilters() {
      this.$emit('update-selected', {
        serviceCategory: this.currentServiceCategory || 'all',
        whatWeDidCategory: this.currentWhatWeDidCategory || 'all',
      });
    },
  },
};

</script>