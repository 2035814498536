<template>
    <a :href="insights.url" class="mb-16 md:w-c1/2-20p">
        <img class="w-full mb-8"  :src="insights.img">
        <h2 >{{ insights.title }}</h2>
        <div class="redactor-content add-border-to-links" v-html="insights.introText"></div>
        <a class="button-empty" :href="insights.url">Read more</a>
    </a>
</template>

<script>
export default {
    props: {
        insights: {
            required: true,
            type: Object
        },
        light: {
            required: true,
            type: Boolean,
        }
    }
}
</script>

