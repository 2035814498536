<template>
    <a :href="news.url" class="md:w-c1/2-20p lg:w-c1/3-12p mb-16">
        <img class="w-full mb-4" :src="news.img">
        <h2 :class="{'text-white':light}" class="text-xl">{{ news.title }}</h2>
        <div class="redactor-content add-border-to-links" v-html="news.summary"></div>
        <p class="mb-0 text-sm">{{ news.articleDate }}<span v-if="news.categoriesString"> • {{ news.categoriesString }}</span></p>
    </a>
</template>

<script>
export default {
    props: {
        news: {
            required: true,
            type: Object
        },
        light: {
            required: true,
            type: Boolean,
        }
    }
}
</script>