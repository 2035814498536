<template>
    <transition enter-class="opacity-0" enter-active-class="ease-out duration-[250ms]" enter-to-class="opacity-100"  appear>
        <div v-show="navOpen" class="fixed inset-0 z-40 transition-opacity">
            <div @click="closeNav" class="absolute inset-0 bg-black opacity-50" tabindex="-1"></div>
        </div>
    </transition>
</template>

<script>
export default {
  props: {
    navOpen: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    closeNav() {
      this.$emit('close-nav');
    }
  }
}
</script>
