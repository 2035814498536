<template>
   <div class="flex flex-wrap justify-between">
            <project-card v-for="project in projects" :key="project.id" :project="project" :light="true"></project-card>
            <div class="h-0 md:w-c1/2-20p lg:w-c1/3-12p"></div>
            <div class="h-0 md:w-c1/2-20p lg:w-c1/3-12p"></div>
    </div>
</template>

<script>
import ProjectCard from "./Projects/ProjectCard.vue";

export default {
    props: {
        projects: {
            required: true,
            type: Array,
        }
    },
    components: {
        ProjectCard
    }
}
</script>