<template> 
  <div class="testimonials-carousel">
    <carousel :wrapAround="true" v-model="currentSlide" :autoplay="(items.length > 1 ? 8000 : 0)" :touchDrag="items.length > 1" :mouseDrag="items.length > 1">
      <slide v-for="(item, key) in items" :key="key">
        <div>
            <div class="flex justify-center mb-6">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.15 26.82" class="w-4 mb48">
                  <path d="M0 0v15.96h7.98L2.36 26.82h7.98l5.62-10.86V0H0zM21.19 0v15.96h7.98l-5.62 10.86h7.98l5.62-10.86V0H21.19z"/>
              </svg>
            </div>
            <p class="font-faktum-extra-bold !text-4xl !leading-[1.25]">{{ item.testimonial }}</p>
            <p class="mb-0 font-faktum-extra-bold">{{ item.who }}</p>
            <p class="">{{ item.company }}</p>
        </div>
      </slide>
    </carousel>
    <div class="flex justify-center" v-if="items.length > 1">
      <button @click="prev" class="border rounded mr-2 border-current p-2 hover:bg-[#D2D3D1] transition-colors duration-150">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-5 h-5 ">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
      </svg>


      </button>
      <button @click="next" class="border rounded border-current p-2 hover:bg-[#D2D3D1] transition-colors duration-150">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-5 h-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
        </svg>

      </button>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      currentSlide: 0,
    };
  },
  methods: {
    next() {
      if (this.currentSlide === this.items.length - 1) this.currentSlide = 0;
      else this.currentSlide++;
    },
    prev() {
      if (this.currentSlide === 0) this.currentSlide = this.items.length - 1;
      else this.currentSlide--;
    },
  },
};
</script>
