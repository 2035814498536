<template>
    <a :href="project.url" class="mb-16 md:w-c1/2-20p lg:w-c1/3-12p">
        <img class="w-full mb-4" :src="project.img">
        <h2 :class="{'text-white':light}">{{ project.title }}</h2>
        <p class="text-sm" :class="{'text-white':light}">{{ project.categoriesString }}</p>
    </a>
</template>

<script>
export default {
    props: {
        project: {
            required: true,
            type: Object
        },
        light: {
            required: true,
            type: Boolean,
        }
    }
}
</script>