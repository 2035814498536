<template>
    <transition enter-from-class="translate-x-full" enter-active-class="ease-out duration-[250ms]" enter-to-class="translate-x-0" appear>
        <div v-show="navOpen" class="fixed inset-y-0 right-0 z-50 w-full max-w-full overflow-y-auto transition-transform bg-brand-orange ">
            <div class="container container-w-12">
                    <div :class="navOpen ? 'block' : 'hidden'" class="absolute z-30 flex items-center right-[25px] top-[55px] md:top-[60px]" @click="closeNav">
                        <span class="mr-2 cursor-pointer text-white">Close</span>
                        <button ref="closeButton" type="button" class="text-white focus:outline-none focus:text-gray-900" aria-label="Close">
                            <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L13.4142 12L19.7071 5.70711C20.0976 5.31658 20.0976 4.68342 19.7071 4.29289C19.3166 3.90237 18.6834 3.90237 18.2929 4.29289L12 10.5858L5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L10.5858 12L4.29289 18.2929C3.90237 18.6834 3.90237 19.3166 4.29289 19.7071C4.68342 20.0976 5.31658 20.0976 5.70711 19.7071L12 13.4142L18.2929 19.7071Z" />
                            </svg>
                        </button>
                    </div>

            </div>
            <div class="container container-w-12 px-0 md:px-[50px]">
                <div class="flex items-center justify-center h-screen">
                    <div class="justify-between text-white font-faktum-extra-bold md:flex md:w-full">
                        <div class="md:w-c1/3-40p">
                            <ul class="mb-0 text-4xl leading-tight md:mb-4">
                                <li>
                                    <a href="/" class="absolute -mt-6">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 cursor-pointer fill-current hover:text-gray-200"  preserveAspectRatio="xMidYMin slice" viewBox="0 0 14.71 16.18">
                                            <path  d="M14.22 6.18l-.69-.69-5-5a1.68 1.68 0 00-2.35 0l-.74.75-4.27 4.25-.68.69A1.66 1.66 0 000 7.35v7.17a1.66 1.66 0 001.66 1.66h3.27v-4.61a1 1 0 011-1H8.8a1 1 0 011 1v4.61h3.27a1.66 1.66 0 001.66-1.66V7.35a1.66 1.66 0 00-.51-1.17z" data-name="Layer 2"/>
                                        </svg>
                                    </a>
                                </li>
                                <li><a :href="entry(36).url" class="hover:text-gray-200">{{ entry(36).title }}</a></li>
                                <li class="md:mb-1"><a :href="entry(3997).url" class="font-faktum-extra-bold hover:text-gray-200">{{ entry(3997).title }}</a></li>
                                <li class="hidden font-serif text-3xl leading-tight md:block">
                                    <a :href="service.uri" class="block hover:text-gray-200" v-for="service in services" :key="service.id">{{ service.title }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="md:w-c1/3-40p">
                            <ul class="mb-0 text-4xl leading-tight md:mb-4">
                                <li><a :href="entry(10).url" class="hover:text-gray-200">{{ entry(10).title }}</a></li>
                                <li><a :href="entry(3999).url" class="hover:text-gray-200">{{ entry(3999).title }}</a></li>
                                <li><a :href="entry(32645).url" class="hover:text-gray-200">{{ entry(32645).title }}</a></li>
                                <li><a :href="entry(6).url" class="hover:text-gray-200">{{ entry(6).title }}</a></li>
                            </ul>
                        </div>
                        <div class="md:w-c1/3-40p">
                            <ul class="mb-0 text-4xl leading-tight md:mb-4">
                                <li><a href="/news" class="hover:text-gray-200">{{ entry(4022).title }}</a></li>
                                <li><a href="/about-us" class="hover:text-gray-200">{{ entry(3995).title }}</a></li>
                                <li class="hidden md:block">
                                    <tiktok :link="socialLinks.tiktok" :nav="true"></tiktok>
                                    <twitter :link="socialLinks.twitter" :nav="true"></twitter>
                                    <instagram :link="socialLinks.instagram" :nav="true"></instagram>
                                    <facebook :link="socialLinks.facebook" :nav="true"></facebook>
                                    <linkedin :link="socialLinks.linkedin" :nav="true"></linkedin>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

import Twitter from '../Socials/Twitter.vue';
import Instagram from '../Socials/Instagram.vue';
import Facebook from '../Socials/Facebook.vue';
import Linkedin from '../Socials/Linkedin.vue';
import Tiktok from '../Socials/TikTok.vue';

export default {
    components: {
        Twitter,
        Instagram,
        Facebook,
        Linkedin,
        Tiktok
    },
    props: {
        navOpen: {
            required: true,
            type: Boolean
        },
        entries: {
            required: true,
            type: Array,
        },
        services: {
            required: true,
            type: Array,
        },
        socialLinks: {
            required: true,
            type: Object,
        }
    },
    methods: {
        closeNav() {
            this.$emit('close-nav');
        },
        entry(id){
            let entry = this.entries.find(e => e.id == id);
            if (entry.title) { entry.title = entry.title.replace(/Our /, ''); }
            return entry ?? {'title':null,'url':null};
        }
    }
}
</script>


