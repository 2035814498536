<template>
  <div class="container container-w-12">
    <nav class="flex justify-center items-center mt-16 space-x-4" v-if="totalPages > 1">
      <button class="hover:text-brand-orange transition-colors duration-150" v-if="currentPage > 1" @click="$emit('change-page', currentPage - 1)" tabindex="0" @keyup.enter="$emit('change-page', currentPage - 1)">
        <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
        </svg>
      </button>
      <div class="w-5" v-else></div>
      <div class="lg:flex hidden space-x-3">
        <span v-for="page in totalPages" :key="'page' + page">
          <button class="font-faktum-extra-bold w-[36px] leading-8 text-white bg-brand-orange border-brand-orange border-2 rounded-[50%] cursor-default" v-if="currentPage == page">
            {{ page }}
          </button>

          <button class="font-faktum-extra-bold w-[36px] leading-8 text-brand-orange bg-white border-brand-orange border-2 rounded-[50%] hover:bg-brand-orange hover:text-white transition-colors duration-150" @click="$emit('change-page', page)" v-else>
            {{ page }}
          </button>
        </span>
      </div>
      <button class="hover:text-brand-orange transition-colors duration-150" v-if="currentPage < totalPages" @click="$emit('change-page', currentPage + 1)" tabindex="0" @keyup.enter="$emit('change-page', currentPage + 1)">
        <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
      <div class="w-5" v-else></div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      required: true,
      type: Number,
    },
    totalPages: {
      required: true,
      type: Number,
    },
  },
};
</script>
