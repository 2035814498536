<template>
  <div>
    <div class="space-y-12 scrollToHere">
      <case-studies-filter :service-categories="serviceCategories" :selected-service-category="selectedServiceCategory" :what-we-did-categories="whatWeDidCategories" :selected-what-we-did-category="selectedWhatWeDidCategory" @update-selected="updateSelected" />

      <case-studies-results :case-studies-to-show="caseStudiesToShowPaginated" :service-categories="serviceCategories" :what-we-did-categories="whatWeDidCategories" />

      <pagination :currentPage="currentPage" :totalPages="pageCount" @change-page="changePage" />
    </div>
  </div>
</template>

<script>
import CaseStudiesFilter from "./CaseStudiesFilters.vue";
import CaseStudiesResults from "./CaseStudiesResults.vue";
import Pagination from "../Pagination.vue";

export default {
  props: {
    serviceCategories: {
      required: true,
      type: Array,
    },
    whatWeDidCategories: {
      required: true,
      type: Array,
    },
    caseStudies: {
      required: true,
      type: Array,
    },
  },
  components: {
    CaseStudiesFilter,
    CaseStudiesResults,
    Pagination,
  },
  data() {
    return {
      selectedServiceCategory: "",
      selectedWhatWeDidCategory: "",
      caseStudiesToShow: [],
      caseStudiesToShowPaginated: [],
      currentPage: 1,
      articlesPerPage:18,
    };
  },
  created() {
    window.onpopstate = (event) => {
      this.selectedServiceCategory = event.state.serviceCategory;
      this.updateFilters();
    };
  },
  mounted() {
    this.setInitialSelectedCategory();
    this.updateFilters();
  },
  computed: {
    pageCount() {
        return Math.ceil(this.caseStudiesToShow.length / this.articlesPerPage);
      },
  },
  methods: {
    updateSelected(data) {
      this.selectedServiceCategory = data.serviceCategory;
      this.selectedWhatWeDidCategory = data.whatWeDidCategory;
      this.currentPage = 1;
      this.updateFilters();
    },
    updateFilters() {
      this.updateUrl();
      let caseStudiesToShow = this.caseStudies;

      if (this.selectedServiceCategory != "all") {
        caseStudiesToShow = caseStudiesToShow.filter((caseStudy) => caseStudy.serviceIds.includes(this.selectedServiceCategory));
      }

      if (this.selectedWhatWeDidCategory != "all") {
        caseStudiesToShow = caseStudiesToShow.filter((caseStudy) => caseStudy.whatWeDidIds.includes(this.selectedWhatWeDidCategory));
      }

      this.caseStudiesToShow = caseStudiesToShow;

      this.caseStudiesToShowPaginated = caseStudiesToShow.slice(this.articlesPerPage * (this.currentPage - 1), this.articlesPerPage * this.currentPage);
    },
    setInitialSelectedCategory() {
      this.selectedServiceCategory = "all";
      this.selectedWhatWeDidCategory = "all";

      // check to see if we have a category via the query string
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("service")) {
        this.serviceCategories.forEach((category) => {
          if (category.slug == urlParams.get("service")) {
            this.selectedServiceCategory = category.id;
          }
        });
      }
      if (urlParams.has("whatwedid")) {
        this.whatWeDidCategories.forEach((category) => {
          if (category.slug == urlParams.get("whatwedid")) {
            this.selectedWhatWeDidCategory = category.id;
          }
        });
      }
    },
    updateUrl() {
      const chosenServiceCategory = this.serviceCategories.filter((category) => category.id == this.selectedServiceCategory)[0];
      const chosenWhatWeDidCategory = this.whatWeDidCategories.filter((category) => category.id == this.selectedWhatWeDidCategory)[0];

      const urlParams = new URLSearchParams(window.location.search);
      // if we have 'all' selected (ie. no matching category id was found) we dont want a query string
      if (!chosenServiceCategory && !chosenWhatWeDidCategory && (urlParams.has("service") || urlParams.has("whatwedid"))) {
        history.pushState({ serviceCategory: null, whatWeDidCategory: null }, "", "/case-studies");
        return;
      }
      // only push a new state to the history if its actually changing
      if ((chosenServiceCategory && urlParams.get("service") != chosenServiceCategory.slug) || (chosenWhatWeDidCategory && urlParams.get("whatwedid") != chosenWhatWeDidCategory.slug)) {
        let queryString = new URLSearchParams();

        if (chosenServiceCategory) {
          queryString.append("service", chosenServiceCategory.slug);
        }

        if (chosenWhatWeDidCategory) {
          queryString.append("whatwedid", chosenWhatWeDidCategory.slug);
        }

        history.pushState({ serviceCategory: chosenServiceCategory?.id || null, whatWeDidCategory: chosenWhatWeDidCategory?.id || null }, "", "/case-studies?" + queryString.toString());
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.updateFilters();
      document.querySelector('.scrollToHere').scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
