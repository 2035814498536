import "../css/app.css";
import 'flowbite';

window.addEventListener("load", function () {

  window.cookieconsent.initialise({
    palette: {
      popup: {
        background: "#646361",
        text: "white",
      },
      button: {
        background: "#ee592a",
        text: "#ffffff",
      },
    },
  });
});
import { createApp, defineAsyncComponent, ref } from 'vue';


import Overlay from "./components/Navigation/Overlay.vue";
import LogoAndMenuButton from "./components/Navigation/LogoAndMenuButton.vue";
import Navigation from "./components/Navigation/Navigation.vue";
import Tiktok from "./components/Socials/TikTok.vue";
import Twitter from "./components/Socials/Twitter.vue";
import Instagram from "./components/Socials/Instagram.vue";
import Facebook from "./components/Socials/Facebook.vue";
import Linkedin from "./components/Socials/Linkedin.vue";

import News from "./components/News/News.vue";
import Insights from "./components/Insights/Insights.vue";
import CaseStudies from "./components/CaseStudies/CaseStudies.vue";
import Projects from "./components/Projects/Projects.vue";
import ServiceDetailCard from "./components/Services/ServiceDetailCard.vue";
import RelatedProjects from "./components/RelatedProjects.vue";
import TestimonialsCarousel from "./components/Carousels/TestimonialsCarousel.vue";
import DropFile from "./components/Services/DropFile.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";



const app = createApp({
  delimiters: ['${', '}'],

  components: {
    Overlay,
    LogoAndMenuButton,
    Navigation,
    Tiktok,
    Twitter,
    Instagram,
    Facebook,
    Linkedin,
    News,
    CaseStudies,
    Projects,
    ServiceDetailCard,
    RelatedProjects,
    Insights,
    TestimonialsCarousel,
    DropFile,
    LottieAnimation

  },
  created() {
    document.addEventListener("click", this.documentClick);

    // rainbow selection
    // colors copied from TW
    const rainbow = ["#03E58C", "#EC0056", "#F9B122"];

    let colorIndex = 0;
    document.addEventListener("selectstart", () => {
      colorIndex++;
      if (colorIndex >= rainbow.length) colorIndex = 0;
      document.querySelector("body").style.setProperty("--select-bg", `${rainbow[colorIndex]}`);
    });
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
  // watch: {
  //   navOpen: {
  //     immediate: true,
  //     handler(navOpen) {
  //       if (navOpen) {
  //         document.body.style.setProperty("overflow", "hidden");
  //       } else {
  //         document.body.style.removeProperty("overflow");
  //       }
  //     },
  //   },
  // },

  setup() {

    const navOpen = ref(false);
    const headerColor = ref("text-black");
    const gRecaptchaResponse = ref(null);

    function openNav() {
      navOpen.value = true;
    }
    function closeNav() {
      navOpen.value = false;
    }
    function changeHeaderColor(newTextColor) {
      headerColor.value = newTextColor;
    }


    // digital form

    const firstName = ref('');
    const lastName = ref('');
    const message = ref('');
    const email = ref('');
    const phoneNumber = ref('');
    const companyName = ref('');
    const website = ref('');
    const joinOurNewsletter = ref(false);
    const howDidYouFirstHearAboutUs = ref('');
    const hpValue = ref('');

    const errors = ref([]);
    const payload = ref(null); // from hidden input
    const hash = ref(null); // from hidden input
    const hpName = ref(null); // from hidden input
    const formSuccess = ref(false);


    function getErrors(property) {
      const newErrors = [];
      if (!errors.value.length) {
        return newErrors;
      }
      for (let i = 0; i < errors.value.length; i++) {
        if (errors.value[i].key === property) {
          newErrors.push(errors.value[i].message);
        }
      }
      return newErrors;
    }

    function validate(property) {
      if (!property) {
        errors.value = [];
      } else {
        errors.value = errors.value.filter((item) => item.key !== property);
      }
      if ((!property || property === "firstName") && !firstName.value) {
        errors.value.push({ key: "firstName", message: "This first name field is required" });
      }
      if ((!property || property === "lastName") && !lastName.value) {
        errors.value.push({ key: "lastName", message: "This last name field is required" });
      }
      if ((!property || property === "email") && !validateEmail(email.value)) {
        errors.value.push({ key: "email", message: "Please enter a valid email addres" });
      }
      if ((!property || property === "phoneNumber") && !phoneNumber.value) {
        errors.value.push({ key: "phoneNumber", message: "This phone number field is required" });
      }
      if ((!property || property === "companyName") && !companyName.value) {
        errors.value.push({ key: "companyName", message: "This company name field is required" });
      }
      if ((!property || property === "website") && !website.value) {
        errors.value.push({ key: "website", message: "This website URL field is required" });
      }
      if ((!property || property === "message") && !message.value) {
        errors.value.push({ key: "message", message: "This message field is required" });
      }

      if ((!property || property === "howDidYouFirstHearAboutUs") && !howDidYouFirstHearAboutUs.value) {
        errors.value.push({ key: "howDidYouFirstHearAboutUs", message: "This field is required" });
      }
    }

    function onSubmit(evt) {


      validate();

      if (errors.value.length) {
        return false;
      }
      const form = evt.target;
      const data = new FormData(form);
      const request = new XMLHttpRequest();

      // Add Honeypot field
      data.append("freeform_form_handle", "servicesForm");

      // Add action which handles freeform validation
      data.append("action", "freeform/submit");

      // Add the form's payload (when using Encrypted Payload)
      data.append("freeform_payload", payload.value.value);

      // Add the form's hash, so that it gets recognized by the form service
      data.append("formHash", hash.value.value);

      // Add the CSRF token if it's enabled for the site
      data.append(window.csrfTokenName, window.csrfTokenValue);

      // Append the data
      data.append("firstName", firstName.value);
      data.append("lastName", lastName.value);
      data.append("email", email.value);
      data.append("phoneNumber", phoneNumber.value);
      data.append("companyName", companyName.value);
      data.append("website", website.value);
      data.append("message", message.value);
      data.append("howDidYouFirstHearAboutUs", howDidYouFirstHearAboutUs.value);

      // let files = null;
      // if (document.getElementById('fileInput').files.length > 0) {
      //   files = document.getElementById('fileInput').files;
      //   data.append("gotABriefOrRfpUploadItHere", files[0]);
      // }
      // console.log(files);


      // Create a request
      request.open("POST", window.location.href, true);
      request.setRequestHeader("Cache-Control", "no-cache");

      // Add the AJAX headers specific to Craft CMS
      request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      request.setRequestHeader("HTTP_X_REQUESTED_WITH", "XMLHttpRequest");

      // Attach the response handler callback
      request.onload = () => {
        if (request.status === 200) {
          const response = JSON.parse(request.response);
          const { success, finished, errors, formErrors } = response;
          if (success && finished) {
            // Reset the form so that the user may enter fresh information
            form.reset();
            formSuccess.value=true;
          } else if (errors || formErrors) {
            console.error(errors, formErrors);
          }
        } else {
          console.error(request);
        }
      };

      // Send the request
      request.send(data);

      return false;
    }

    function validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }


    return {
      navOpen,
      headerColor,
      gRecaptchaResponse,
      openNav,
      closeNav,
      changeHeaderColor,
      firstName,
      lastName,
      message,
      errors,
      getErrors,
      validate,
      onSubmit,
      payload,
      hash,
      email,
      phoneNumber,
      companyName,
      website,
      howDidYouFirstHearAboutUs,
      hpName,
      hpValue,
      formSuccess,
      joinOurNewsletter

    };
  },
});


app
.mount("#app");

if ($(".glide")[0]){
  var glideGallery = new Glide('.glide', {
    type: 'carousel',
    animationDuration: 1500,
    autoplay: 3000,
    focusAt: 'center',
    startAt: 1,
    perView: 3,
    // set a value to show the previous and next slides peeking in
    peek: {
        before: 0,
        after: 0
    },
    breakpoints: {
      600: { perView: 1, gap: 12 },
      1200: { perView: 2, gap: 24 },
      1400: { perView: 3 }
    },
    gap: 48
  });

  glideGallery.mount();
}