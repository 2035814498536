<template>
    <div>
        <div class="mb-8">
            <div class="container container-w-12">
                <div class=" pb-2 border-b border-gray-700">
                    <span class="pr-6 text-gray-600">Filter By:</span>
                    <ul class="font-faktum-extra-bold inline">
                        <li class="inline pr-6" v-for="category in categories" :key="category.id">
                            <input type="radio" :value="category.id" :id="'category_'+category.id" @change="updateProjectsFilter" v-model="selectedCategory" class="hidden">
                               <label :for="'category_'+category.id" class="hover:text-brand-orange cursor-pointer"  :class="{'text-brand-orange':selectedCategory==category.id}">
                                {{ category.title }}
                            </label>
                        </li>
                        <li class="inline cursor-pointer">
                            <input type="radio" :value="'all'" :id="'all'" @change="updateProjectsFilter" v-model="selectedCategory" class="hidden">
                            <label for="all" class="cursor-pointer" style="white-space:nowrap;" :class="{'text-brand-orange':selectedCategory=='all','hover:text-brand-orange':selectedCategory!='all'}">
                            All
                        </label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container container-w-12">
            <div class="flex flex-wrap justify-between">
                <project-card v-for="project in projectsToShow" :key="project.id" :project="project" :light="false"></project-card>
                <div class="md:w-c1/2-20p lg:w-c1/3-12p h-0"></div>
                <div class="md:w-c1/2-20p lg:w-c1/3-12p h-0"></div>
            </div>
        </div>
    </div>
</template>

<script>

import ProjectCard from './ProjectCard.vue';

export default {
    props: {
        categories: {
            required: true,
            type: Array,
        },
        projects: {
            required: true,
            type: Array,
        }
    },
    components: {
        ProjectCard
    },
    data() {
        return {
            selectedCategory: '',
            projectsToShow: []
        }
    },
    created() {
        window.onpopstate = (event) => {
            this.selectedCategory = event.state.category;
            this.updateProjectsFilter();
        };
    },
    mounted() {
        this.setInitialSelectedCategory();
        this.updateProjectsFilter();
    },
    methods: {
        updateProjectsFilter() {
            this.updateUrl();
            if (this.selectedCategory == 'all') { this.projectsToShow = this.projects; return; }
            this.projectsToShow = this.projects.filter((project) => project.categoriesIds.includes(this.selectedCategory));
        },
        setInitialSelectedCategory() {
            this.selectedCategory = 'all';
            // check to see if we have a category via the query string
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('show')) {
                this.categories.forEach((category) => {
                    if (category.slug == urlParams.get('show')) { this.selectedCategory = category.id }
                });
            };
        },
        updateUrl() {
            const chosenCategory = this.categories.filter((category) => category.id == this.selectedCategory)[0];
            const urlParams = new URLSearchParams(window.location.search);
            // if we have 'all' selected (ie. no matching category id was found) we dont want a query string
            if (!chosenCategory && urlParams.has('show')) {
                history.pushState({ 'category': null }, '', '/our-work');
                return;
            }
            // only push a new state to the history if its actually changing
            if (chosenCategory && urlParams.get('show') != chosenCategory.slug) {
                history.pushState({ 'category': chosenCategory.id }, '', '/our-work?show=' + chosenCategory.slug);
            }
        }
    }
}
</script>